<template>
  <div class="sidebar">
    <router-link v-for="(item, index) in items.filter(x => x.show)" :key="index" :to="item.to" class="sidebar_item">
      <img :class="item.legend.toLowerCase().includes('proposta') && 'h-10'" :src="item.uri" alt="inicio">
      <!-- <img :src="item.uri" alt="inicio"> -->
      <span class="tooltip">{{ item.legend }}</span>
    </router-link>
    <p class="sidebar_item" @click="$emit('logout')">
      <img :src="require('@/assets/images/investor_panel/logout.svg')" alt="inicio">
      <span class="tooltip">Sair</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'investor-sidebar',
  data() {
    return {
      items: [
        {
          to: '/parceiro/painel',
          legend: 'Propostas',
          uri: require('@/assets/images/partner_panel/propostas.svg'),
          show: true
        },

        {
          to: '/parceiro/nova-proposta',
          legend: 'Nova proposta',
          uri: require('@/assets/images/partner_panel/nova_proposta.svg'),
          show: true,
        },
        {
          to: '/parceiro/fechamentos',
          legend: 'Fechamentos',
          uri: require('@/assets/images/investor_panel/analytics.svg'),
          show: true,
        },
        {
          to: '/parceiro/scr',
          legend: 'SCR',
          uri: require('@/assets/images/investor_panel/analytics.svg'),
          show: this.$store.state.partner.scr
        },
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.sidebar
  @apply h-full w-20 shadow-md z-10

.sidebar_item
  @apply relative flex flex-col items-center justify-center w-full h-20 bg-white border border-gray-400 cursor-pointer
  &.router-link-exact-active
    @apply bg-secondary border-none
    &>img
      filter: brightness(0) invert(1)
  &:hover
    @apply bg-secondary border-secondary
    opacity: 0.85
    &>img
      filter: brightness(0) invert(1)
    &>.tooltip
      visibility: visible
  &>.tooltip
    @apply absolute invisible bg-secondary text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-4 z-10
    left: 110%
  &>.tooltip::after
    @apply absolute border-transparent
    content: ""
    top: 50%
    right: 100%
    margin-top: -5px
    border-width: 5px
    border-style: solid
    border-right-color: #5316CD
</style>
