<template>
  <div class="navbar">
    <transition name="fade">
      <modal-nav v-show="modalnav" @closeModal="modalnav = false">
        <div slot="body" class="flex flex-col items-center mt-4">
          <p class="link_dashboard" @click="modalnav = false; $router.push('/parceiro/painel')">Propostas</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/parceiro/relatorios')">Relatórios</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/parceiro/nova-proposta')">Nova proposta</p>
          <p class="link_dashboard" @click="modalnav = false; $router.push('/parceiro/fechamentos')">Fechamentos</p>
          <p class="link_dashboard" v-if="$store.state.partner.scr"
            @click="modalnav = false; $router.push('/parceiro/scr')">SCR</p>

          <p class="link_dashboard" @click="$emit('logout')">Sair</p>
        </div>
      </modal-nav>
    </transition>
    <header>
      <router-link class="logo" to="/parceiro/painel"></router-link>
      <img @click="modalnav = true" class="bars" src="@/assets/images/menu.svg" alt="">
      <p class="font-bold my-2 text-sm">{{ ($store.state.partner && $store.state.partner.email ?
        $store.state.partner.email
        : '') }}</p>

    </header>
  </div>
</template>

<script>
import ModalNav from '@/components/Modal/ModalNav'

export default {
  name: 'partner-navbar',
  components: { ModalNav },
  data() {
    return {
      modalnav: false
    }
  },


}
</script>

<style lang="sass" scoped>

.navbar
  @apply w-full py-2 flex justify-center z-40
  @screen md
  @apply
    @apply items-center

header
  @apply h-16 w-full flex flex-row items-center justify-between
  @screen md
    @apply h-16 w-full flex flex-row items-center justify-between
  @screen lg
    @apply h-16 w-full flex flex-row items-center justify-between

.logo
  @apply bg-no-repeat bg-center h-10 w-32 cursor-pointer bg-contain
  background-image: url("../../../assets/images/logo.svg")
  @screen lg
    @apply h-12 w-40

nav
  @apply hidden
  @screen md
    @apply block flex items-center
  @screen lg
    @apply block flex items-center

.money_bag_green
  @apply relative flex justify-center bg-no-repeat bg-center h-10 w-10 cursor-pointer shadow rounded-full bg-primary ml-2
  background-image: url("../../../assets/images/investor_panel/depositar.svg")
  &:hover
    opacity: 0.9
    &>.tooltip
      visibility: visible
  &>.tooltip
    @apply absolute invisible bg-primary text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-4 z-10 select-none
    bottom: -80%
  &>.tooltip::after
    @apply absolute border-transparent mx-auto
    content: ""
    bottom: 100%
    left: 50%
    transform: rotate(90deg)
    margin-left: -5px
    border-width: 5px
    border-style: solid
    border-right-color: #02B26E
  @screen md
    @apply mr-4 ml-0

.money_bag_red
  @apply relative flex justify-center bg-no-repeat bg-center h-10 w-10 cursor-pointer shadow rounded-full bg-red-600 mr-2
  background-image: url("../../../assets/images/investor_panel/sacar.svg")
  &:hover
    opacity: 0.9
    &>.tooltip
      visibility: visible
  &>.tooltip
    @apply absolute invisible bg-red-600 text-white text-center text-xss font-semibold rounded-sm whitespace-no-wrap py-1 px-4 z-10 select-none
    bottom: -80%
  &>.tooltip::after
    @apply absolute border-transparent mx-auto
    content: ""
    bottom: 100%
    left: 50%
    transform: rotate(90deg)
    margin-left: -5px
    border-width: 5px
    border-style: solid
    border-right-color: #e53e3e
  @screen md
    @apply mr-4

.avatar
  @apply h-14 w-14 shadow rounded-full
  @screen md
    @apply mr-4

.badge
  @apply flex items-center justify-center bg-red-600 rounded-full text-xs text-white h-4 w-4 absolute bottom-0 right-0 shadow

.username
  @apply font-semibold text-sm text-black opacity-75 uppercase

.user_money
  @apply text-xs text-primary text-right

.bars
  @apply block w-6 h-6
  @screen md
    @apply hidden
  @screen lg
    @apply hidden
</style>
