<template>
  <div class="main">
    <div class="w-full flex flex-col px-6 justify-center bg-white shadow-md">
      <nav-bar @logout="logout"/>
    </div>
    <div class="flex flex-row">
      <side-bar class="sidebar" @logout="logout"/>
      <div class="container">
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Partner/NavBar/NavBar'
import SideBar from '@/components/Partner/SideBar/SideBar'

export default {
  name: 'partner-dashboard',
  components: { NavBar, SideBar },
  methods: {
    logout () {
      this.$store.dispatch('setLogout')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="sass" scoped>

.main
  @apply w-full min-h-screen max-h-full
  background: #F5F5F5

.sidebar
  @apply hidden
  @screen md
    @apply block

.container
  padding-right: 1rem !important
  padding-left: 1rem !important
  @screen md
    padding-right: 2rem !important
    padding-left: 2rem !important
</style>
